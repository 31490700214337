/* You can add global styles to this file, and also import other style files */
@use "../public/assets/fonts/fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@ng-select/ng-select/themes/default.theme.css";
@import "js-datepicker/dist/datepicker.min.css";


.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  border-color: #babbbd;
  height: 50px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 12px;
}
@font-face {
  font-family: 'calibri';
  src: url('./calibri-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'calibri';
  src: url('./calibri-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'calibri';
  src: url('./calibri-bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'calibri';
  src: url('./calibri-bold-italic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
